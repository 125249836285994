// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// icons
import Eye from '@/components/icons/Eye.vue';
import Copy from '@/components/icons/Copy.vue';
import EyeClose from '@/components/icons/EyeClose.vue';
import Tick from '@/components/icons/Tick.vue';
import Dashboard from '@/components/icons/Dashboard.vue';
import Balances from '@/components/icons/Balances.vue';
import Withdraws from '@/components/icons/Withdraws.vue';
import Payments from '@/components/icons/Payments.vue';
import Checkouts from '@/components/icons/Checkouts.vue';
import Invoices from '@/components/icons/Invoices.vue';
import Settings from '@/components/icons/Settings.vue';
import CheckboxOff from '@/components/icons/CheckboxOff.vue';
import CheckboxOn from '@/components/icons/CheckboxOn.vue';
import Error from '@/components/icons/Error.vue';
import File from '@/components/icons/File.vue';
import ArrowLeft from '@/components/icons/ArrowLeft.vue';
import ArrowRight from '@/components/icons/ArrowRight.vue';
import ExchangeIcon from '@/components/icons/ExchangeIcon.vue';
import Dividends from '@/components/icons/Dividends.vue';
import Orders from '@/components/icons/Orders.vue';
import Teams from '@/components/icons/Teams.vue';
import { dark, light } from '@/assets/configs/colors';
import Shield from '@/components/icons/Shield.vue';

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light,
      dark,
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      shield: {
        component: Shield,
        props: { name: 'shield' },
      },
      eye: {
        component: Eye,
        props: { name: 'eye' },
      },
      copy: {
        component: Copy,
        props: { name: 'copy' },
      },
      eyeClose: {
        component: EyeClose,
        props: { name: 'eyeClose' },
      },
      tick: {
        component: Tick,
        props: { name: 'tick' },
      },
      dashboard: {
        component: Dashboard,
        props: { name: 'dashboard' },
      },
      balances: {
        component: Balances,
        props: { name: 'balances' },
      },
      withdraws: {
        component: Withdraws,
        props: { name: 'withdraws' },
      },
      exchangeIcon: {
        component: ExchangeIcon,
        props: { name: 'exchangeIcon' },
      },
      orders: {
        component: Orders,
        props: { name: 'orders' },
      },
      payments: {
        component: Payments,
        props: { name: 'payments' },
      },
      checkouts: {
        component: Checkouts,
        props: { name: 'checkouts' },
      },
      invoices: {
        component: Invoices,
        props: { name: 'invoices' },
      },
      settings: {
        component: Settings,
        props: { name: 'settings' },
      },
      checkboxOff: {
        component: CheckboxOff,
        props: { name: 'checkboxOff' },
      },
      checkboxOn: {
        component: CheckboxOn,
        props: { name: 'checkboxOn' },
      },
      error: {
        component: Error,
        props: { name: 'error' },
      },
      file: {
        component: File,
        props: { name: 'file' },
      },
      arrowLeft: {
        component: ArrowLeft,
        props: { name: 'arrowLeft' },
      },
      arrowRight: {
        component: ArrowRight,
        props: { name: 'arrowRight' },
      },
      teams: {
        component: Teams,
        props: { name: 'teams' },
      },
      dividends: {
        component: Dividends,
        props: { name: 'dividends' },
      },
    },
  },
});
