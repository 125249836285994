<template>
  <v-app id="app">
    <router-view></router-view>
    <Notification />
    <LoadNotification />

    <div id="root-tooltip-wrapper"></div>
    <!-- <CountDownNotification /> -->
  </v-app>
</template>

<script>
  import Notification from '@/components/notification/Notification.vue';
  import LoadNotification from '@/components/load-notification/LoadNotification.vue';
  // import CountDownNotification from '@/components/count-down-notification/CountDownNotification.vue';
  import StorageHelper from '@/helpers/localStorageHelper';

  export default {
    name: 'App',
    data: () => ({
      nightMode: false,
    }),
    components: {
      Notification,
      LoadNotification,
      // CountDownNotification,
    },
    created() {
      this.$nextTick(() => {
        const nightMode = StorageHelper.get('nightMode');
        if (nightMode !== undefined && nightMode !== null) {
          this.nightMode = nightMode === 'DARK';
        } else {
          this.nightMode = false;
        }
      });
    },
    watch: {
      nightMode(val) {
        this.switchTheme(val);
      },
    },
  };
</script>
