export const light = {
  success: '#00CEC9',
  black: '#2D3436',
  black1: '#2F3542',
  mainColor: '#00CEC9',
  gray: '#B2BEC3',
  gray1: '#ECEEEE',
  grayText: '#636E72',
  borderGray: '#DFE6E9',
  mint: '#F4FCFF',
  violet: '#6C5CE7',
  error: '#FF7675',
  errorWithOpacity30: '#ffeae9',
  beige: '#F5F5F5',
  red: '#FF7675',
  highlightTableRow: '#effaff',
  hoverTableRowBg: '#F8FDFF',

  background: '#FFFFFF',
  bigCircle: '#F4FCFF',
  blackWhite: '#2D3436',
  whiteBlack: '#FFFFFF',
  grayBlack: '#f5f5f5',
  grayWithOpacity30: '#f5f5f5',
  grayWithOpacity50: '#B2BEC3',
  copyIcon: '#6C5CE7',
  whiteRed: '#fff',
  redWhite: '#ff7675',

  tableBG: '#FFF',
  sidebar: '#FFF',
  selector: '#FFF',

  filterItemBG: '#e6e5e5',
  filterItemColor: '#666e71',

  grayWhite: '#B2BEC3',
  yellowBG: '#fffee9',

  mmButton: '#F5F5F5',

  // UI V2
  v2Border: '#E6E5E5',
  v2primary: '#705ceb',
  v2third: '#D4CEF9',
  v2forth: '#F4F2FD',
  v2primaryLight: '#F4F2FD',
  v2secondary: '#f4f2fd',
  textColor1: '#656e71',
  v2PrimaryRed: '#DD5A55',
  v2SecondaryRed: '#FEF5F4',
  v2redBackgound: '#fff4f4',
  v2darkGrey: '#666E71',

  v2Green: '#09B462',

  v2chips: '#EDEDED',

  riskCircle1: '#17B18C',
  riskCircle2: '#F0B057',
  riskCircle3: '#D9433C',
};

export const dark = {
  success: '#00CEC9',
  black: '#2D3436',
  black1: '#2F3542',
  mainColor: '#00CEC9',
  gray: '#B2BEC3',
  gray1: '#ECEEEE',
  grayText: '#fff',
  borderGray: '#DFE6E9',
  mint: '#2e2e2e',
  violet: '#6C5CE7',
  error: '#FF7675',
  errorWithOpacity30: '#ffeae9',
  beige: '#111',
  red: '#FF7675',
  highlightTableRow: '#000',

  background: '#2D3436',
  bigCircle: '#161616',
  blackWhite: '#FFFFFF',
  whiteBlack: '#2D3436',
  grayBlack: '#000',
  grayWithOpacity30: '#414141',
  grayWithOpacity50: '#626262',
  copyIcon: '#888888',
  whiteRed: '#ff7675',
  redWhite: '#fff',

  tableBG: '#1E1E1E',
  sidebar: '#1e1e1e',
  selector: '#000',

  hoverTableRowBg: '#333',
  filterItemBG: '#666e71',
  filterItemColor: '#e6e5e5',

  grayWhite: '#fff',
  yellowBG: '#464646',

  mmButton: '#6c6c6c',

  // UI V2
  v2Border: '#E6E5E5',
  v2primary: '#705ceb',
  v2third: '#D4CEF9',
  v2forth: '#181818',
  v2primaryLight: '#F4F2FD',
  v2secondary: '#a895ff',
  textColor1: '#656e71',
  v2PrimaryRed: '#DD5A55',
  v2SecondaryRed: '#FEF5F4',
  v2redBackgound: '#fff4f4',
  v2darkGrey: '#666E71',

  v2Green: '#09B462',

  v2chips: '#EDEDED',

  riskCircle1: '#17B18C',
  riskCircle2: '#F0B057',
  riskCircle3: '#D9433C',
};
