import { IScore } from '@/models/interfaces/score/IScore';
import { IScoreStore } from '@/models/interfaces/stores/IScoreStore';

// TODO: Check and make correct types here
export default {
  state: {
    scoreData: null,
  },
  getters: {
    scoreData: (state: IScoreStore) => state.scoreData,
  },
  mutations: {
    SET_SCORE_DATA(state: IScoreStore, data: IScore) {
      state.scoreData = data;
    },
    RESET(state: IScoreStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        scoreData: null,
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setScoreData(context: any, data: any) {
      context.commit('SET_SCORE_DATA', data);
    },
  },
};
