// eslint-disable-next-line
export enum RoleTypeAction {
  // eslint-disable-next-line
  READ,
  // eslint-disable-next-line
  WRITE,
}

interface RolesAccess {
  [x: string]: { read: string[]; write: string[] };
}

const merchantAccess = [
  'DASHBOARD',
  'BALANCES',
  'OPERATIONS',
  'REPORT',
  'SETTINGS',
  'STATEMENT',
  'PROFILE',
  'TEAM',
];

const rolesAccess: RolesAccess = {
  ROLE_ADMIN: {
    read: ['ALL'],
    write: ['ALL'],
  },
  ROLE_DIVIDEND_ADMIN: {
    read: [
      'DIVIDENDS',
      'DIVIDENDSSPREADS',
      'BALANCES',
      'OPERATIONS',
      'EXCHANGE',
      'SEND_FUNDS',
      'SETTINGS',
      'PROFILE',
      'VERIFICATION',
      'ACTIVE_CURRENCY',
      'STATEMENT',
      'DEDICATED_ADDRESS',
    ],
    write: ['EXCHANGE', 'SEND_FUNDS', 'DIVIDENDS', 'FIN_REPORT'],
  },
  ROLE_SUPPORT_LEAD: {
    read: [
      'API',
      'BALANCES',
      'CHECKOUTS',
      'PAYOUTS',
      'PAYOUT_DETAILS',
      'OPERATIONS',
      'ORDERS',
      'REPORT',
      'SETTINGS',
      'PROFILE',
      'VERIFICATION',
      'ACTIVE_CURRENCY',
      'STATEMENT',
      'DEDICATED_ADDRESS',
    ],
    write: [
      'API_KEYS',
      'CREATE_PAYMENT_LINK',
      'EDIT_PAYMENT_LINK',
      'STATEMENT',
      'ORDER',
      'MANAGE_MASS_PAYOUTS',
    ],
  },
  ROLE_SUPPORT_MANAGER: {
    read: [
      'OPERATIONS',
      'ORDERS',
      'CHECKOUTS',
      'PROFILE',
      'VERIFICATION',
      'ACTIVE_CURRENCY',
      'STATEMENT',
      'SETTINGS',
      'DEDICATED_ADDRESS',
    ],
    write: ['STATEMENT'],
  },
  ROLE_FINANCE_MANAGER: {
    read: [
      'DASHBOARD',
      'BALANCES',
      'PAYOUTS',
      'EXCHANGE',
      'SEND_FUNDS',
      'OPERATIONS',
      'REPORT',
      'SETTINGS',
      'STATEMENT',
      'ACTIVE_CURRENCY',
      'PROFILE',
      'PAYOUTS',
      'PAYOUT_DETAILS',
      'VERIFICATION',
      'DEDICATED_ADDRESS',
    ],
    write: [
      'EXCHANGE',
      'SEND_FUNDS',
      'STATEMENT',
      'PAYOUT',
      'FIN_REPORT',
      'APPROVE_PAYOUTS',
      'ACTIVE_CURRENCY',
      'RESEND_PAYOUTS',
      'MANAGE_SINGLE_PAYOUTS',
      'MANAGE_MASS_PAYOUTS',
    ],
  },
};

export function isDividendAccess(self: any) {
  if (!self.state.userData?.roles?.length) return false;

  return self.state.userData.roles[0] === 'ROLE_DIVIDEND_ADMIN';
}

export function checkAccess(
  self: any,
  action: string,
  actionType: RoleTypeAction
) {
  if (!self.state.userData?.roles?.length) return false;

  const role: string = self.state.userData.roles[0];

  if (actionType === RoleTypeAction.READ) {
    const { read } = rolesAccess[role];

    return read?.length && (read.includes('ALL') || read.includes(action));
  }
  if (actionType === RoleTypeAction.WRITE) {
    const { write } = rolesAccess[role];

    return write?.length && (write.includes('ALL') || write.includes(action));
  }

  return false;
}

export function checkMerchant(self: any, action: string) {
  if (self.state.isAggregateData) {
    return merchantAccess.includes(action);
  }

  return true;
}
